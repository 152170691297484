import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { useEffect } from 'react';
import { MultiSelect } from "react-multi-select-component";
import './EasyExportComponent.css';
import axios from 'axios';
import { ROOT_DOMAIN_API } from "../../Constant";

function EasyExportComponent(props) {
    const now = new Date();
    const [selectedRange, setSelectedRange] = useState({from: new Date(now.getFullYear(), now.getMonth(), 1), to: new Date()});
    const [dayPickerVisibility, setDayPickerVisibility] = useState(false);
    const [selectedPrio, setSelectedPrio] = useState([]);
    const [selectedPlateform, setSelectedPlateform] = useState([]);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState([]);
    const [selectedSales, setSelectedSales] = useState([]);
    const [selectedComments, setSelectedComments] = useState([]);
    const [selectedFilterComments, setSelectedFilterComments] = useState([]);
    const [activityType, setActivityType] = useState(-1);
    const [activityTypeLabel, setActivityTypeLabel] = useState("Everything");
    const [userRevenue, setUserRevenue] = useState(false);
    const [userRevenueFrom, setUserRevenueFrom] = useState(0);
    const [userRevenueTo, setUserRevenueTo] = useState(0);
    const [userWebsite, setUserWebsite] = useState(false);
    const [userWebsiteFrom, setUserWebsiteFrom] = useState(0);
    const [userWebsiteTo, setUserWebsiteTo] = useState(0);
    const [salesList, setSalesList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [groupByValue, setGroupByValue] = useState("gTotal")
    const [filterDateValue, setFilterDateValue] = useState("fSubDate");
    const [commentsList, setCommentsList] = useState([]);
    const [selectedQuality, setSelectedQuality] = useState([]);
    const [siteDeepsee, setSiteDeepsee] = useState(false);
    const [exportName, setExportName] = useState("");

    const optionsPrio = [
        { label: "P1", value: "1" },
        { label: "P2", value: "2" },
        { label: "P3", value: "3"},
        { label: "P4", value: "4"},
    ];

    const optionsQuality = [
        { label: "-1", value: "-1" },
        { label: "0", value: "0" },
        { label: "1", value: "1"},
        { label: "2", value: "2"},
    ];

    const optionsPlateform = [
        { label: "WW", value: "ww" },
        { label: "ES", value: "es" },
        { label: "US", value: "us"},
        { label: "IT", value: "it"},
        { label: "PT", value: "pt"},
        { label: "DE", value: "de"},
        { label: "RU", value: "ru"},
        { label: "JP", value: "jp"},
    ];

    const optionsAlerts = [
        { label: "Opportunities", value: "alertOpportunities"},
        { label: "Detag", value: "alertDetag"},
        { label: "First Perf", value: "alertFirstPerf"},
    ]

    const toggleDayPickerVisibility = function(){
        if(dayPickerVisibility){
            setDayPickerVisibility(false);
        } else {
            setDayPickerVisibility(true);
        }
    }

    const handleChangeActivityType = function(e){
        setActivityType(e.target.value);
        switch(e.target.value){
            case "-1":
                setActivityTypeLabel("Everything");
                break;
            case "0":
                setActivityTypeLabel("Active");
                break;
            case "1":
                setActivityTypeLabel("All Inactive");
                break;
            case "2":
                setActivityTypeLabel("Inactive without perf");
                break;
            case "3":
                setActivityTypeLabel("Inactive and had perf");
                break;
            default:
                break;
        }
    }

    const handleChangeSiteDeepsee = function(e){
        if(e.target.checked){
            setSiteDeepsee(true);
        } else {
            setSiteDeepsee(false);
        }
    }

    const handleChangeUserRevenue = function(e){
        if(e.target.checked){
            setUserRevenue(true);
        } else {
            setUserRevenue(false);
        }
    }

    const handleChangeUserRevenueFrom = function(e){
        setUserRevenueFrom(e.target.value);
    }

    const handleChangeExportName = function(e){
        setExportName(e.target.value);
    }

    const handleChangeUserRevenueTo = function(e){
        setUserRevenueTo(e.target.value);
    }

    const handleChangeUserWebsite = function(e){
        if(e.target.checked){
            setUserWebsite(true);
        } else {
            setUserWebsite(false);
        }
    }

    const handleChangeUserWebsiteFrom = function(e){
        setUserWebsiteFrom(e.target.value);
    }

    const handleChangeUserWebsiteTo = function(e){
        setUserWebsiteTo(e.target.value);
    }

    const launchExport = function(){
        let data = {}
        data.dateFrom = format(selectedRange.from, 'yyyy-MM-dd');
        data.dateTo = format(selectedRange.to, 'yyyy-MM-dd');
        data.groupBy = groupByValue;
        data.filterPrio = selectedPrio;
        data.alerts = selectedAlerts;
        data.comments = selectedComments;
        data.filterDate = filterDateValue;
        data.filterPlateform = selectedPlateform;
        data.filterAccount = selectedAccount;
        data.filterSales = selectedSales;
        data.activityType = activityType;
        data.userRevenue = userRevenue;
        data.userWebsite = userWebsite;
        data.userRevenueFrom = userRevenueFrom;
        data.userRevenueTo = userRevenueTo;
        data.userWebsiteFrom = userWebsiteFrom;
        data.userWebsiteTo = userWebsiteTo;
        data.metricsPerf = ["performance"];
        data.filterComments = selectedFilterComments;
        data.filterQuality = selectedQuality;
        data.siteDeepsee = siteDeepsee;
        data.exportName = exportName;
        
        (async () => {
            try {
                const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getEasyExport", data);
                const obj = await resp.data;
                if(obj.results){
                    props.refreshExportHistory();
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }

    const handleChangeGroup = function(e){
        setGroupByValue(e.target.value);
    }

    const handleChangeFilterDate = function(e){
        setFilterDateValue(e.target.value);
    }

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getSalesList");
                const obj = await resp.data;
                if(obj.results){
                    let salesListTmp = [];
                    obj.results.forEach((row) => {
                        let obj = {label: row.adm_name, value: row.adm_id};
                        salesListTmp.push(obj);
                    })
                    setSalesList(salesListTmp);
                }
            } catch (error) {
                console.error(error);
            }
        })();
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getCommentsNewCategory");
                const obj = await resp.data;
                if(obj.results){
                    let commentsListTmp = [];
                    obj.results.forEach((row) => {
                        let obj = {label: row.libelle, value: row.id};
                        commentsListTmp.push(obj);
                    })
                    setCommentsList(commentsListTmp);
                }
            } catch (error) {
                console.error(error);
            }
        })();
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getAllCSMUser");
                const obj = await resp.data;
                if(obj.results){
                    let accountListTmp = [];
                    obj.results.forEach((row) => {
                        let obj = {label: row.adm_name, value: row.adm_id};
                        accountListTmp.push(obj);
                    })
                    setAccountList(accountListTmp);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [])
    return (
    <div className={"ExportContainer EasyExport MT10 "+props.childClass}>
        <h2>Request export</h2>
        <div className="FlexRow JustifyBetween">
            <div className="Width50">
                <p className="BoldMediumFont M0 MB5 MT10">Dates</p>
                <div className="FlexRow">
                    <button className="ActionButton BRed" onClick={() => toggleDayPickerVisibility()}>
                        <i className="fa-solid fa-calendar"></i>
                        &nbsp;{format(selectedRange.from, 'yyyy-MM-dd')}
                        &nbsp;<i className="fa-solid fa-arrow-right"></i>
                        &nbsp;{format(selectedRange.to, 'yyyy-MM-dd')}
                    </button>
                    <div className="FlexRow AlignCenter ML10">
                        <input type="radio" id="gDay" name="groupBy" onClick={handleChangeGroup} value="gDay"/><label for="gDay">By day</label>
                    </div>
                    <div className="FlexRow AlignCenter ML10">
                        <input type="radio" id="gMonth" name="groupBy" onClick={handleChangeGroup} value="gMonth"/><label for="gMonth">By month</label>
                    </div>
                    <div className="FlexRow AlignCenter ML10">
                        <input type="radio" id="gTotal" name="groupBy" onClick={handleChangeGroup} value="gTotal" defaultChecked/><label for="gTotal">By period total</label>
                    </div>
                </div>
                <DayPicker
                        format="M/D/YYYY"
                        className={"DayPickerObject " + (dayPickerVisibility ? '' : 'Hide')}
                        mode="range"
                        selected={selectedRange}
                        onSelect={setSelectedRange}
                        />
                <p className="BoldMediumFont M0 MB5 MT10">Filter date with :</p>
                <div className="FlexRow">
                    <div className="FlexCol">
                        <div className="FlexRow AlignCenter">
                            <input type="radio" id="fSubDate" name="filterBy" onClick={handleChangeFilterDate} value="fSubDate" defaultChecked/><label for="fSubDate">Subscription</label>
                        </div>
                        <div className="FlexRow AlignCenter">
                            <input type="radio" id="fPerfDate" name="filterBy" onClick={handleChangeFilterDate} value="fPerfDate"/><label for="fPerfDate">Performance</label>
                        </div>
                        <div className="FlexRow AlignCenter">
                            <input type="radio" id="fProsDate" name="filterBy" onClick={handleChangeFilterDate} value="fProsDate"/><label for="fProsDate">Prospection</label>
                        </div>
                    </div>
                    <div className="FlexCol ML20">
                        <div className="FlexRow AlignCenter">
                            <input type="radio" id="fAlerts" name="filterBy" onClick={handleChangeFilterDate} value="fAlerts"/><label for="fAlerts">Alerts</label>
                            <MultiSelect
                                className={"ML20 MultiSelect "+(filterDateValue === "fAlerts" ? "" : "Hide")}
                                options={optionsAlerts}
                                value={selectedAlerts}
                                onChange={setSelectedAlerts}
                                labelledBy="Select"
                            />
                        </div>
                        <div className="FlexRow AlignCenter">
                            <input type="radio" id="fComments" name="filterBy" onClick={handleChangeFilterDate} value="fComments"/><label for="fComments">Comments</label>
                            <MultiSelect
                                className={"ML20 MultiSelect "+(filterDateValue === "fComments" ? "" : "Hide")}
                                options={commentsList}
                                value={selectedComments}
                                onChange={setSelectedComments}
                                labelledBy="Select"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="Width50 PL50 LeftBorderSeparator">
                <p className="BoldMediumFont M0 MB5 MT10">Filter</p>
                <div className="FlexRow">
                    <div className="FlexCol Width30">
                    <label>Prio</label>
                    <MultiSelect
                        options={optionsPrio}
                        value={selectedPrio}
                        onChange={setSelectedPrio}
                        labelledBy="Select"
                    />
                    </div>
                    <div className="FlexCol Width30 ML20">
                    <label>Plateform</label>
                    <MultiSelect
                        options={optionsPlateform}
                        value={selectedPlateform}
                        onChange={setSelectedPlateform}
                        labelledBy="Select"
                    />
                    </div>
                </div>
                <div className="FlexRow MT10">
                    <div className="FlexCol Width30">
                    <label>Sales</label>
                    <MultiSelect
                        options={salesList}
                        value={selectedSales}
                        onChange={setSelectedSales}
                        labelledBy="Select"
                    />
                    </div>
                    <div className="FlexCol Width30 ML20">
                    <label>Account</label>
                    <MultiSelect
                        options={accountList}
                        value={selectedAccount}
                        onChange={setSelectedAccount}
                        labelledBy="Select"
                    />
                    </div>
                </div>
                <div className="FlexRow MT10">
                    <div className="FlexCol Width30">
                    <label>Comments</label>
                    <MultiSelect
                        options={commentsList}
                        value={selectedFilterComments}
                        onChange={setSelectedFilterComments}
                        labelledBy="Select"
                    />
                    </div>
                    <div className="FlexCol Width30 ML20">
                    <label>Quality</label>
                    <MultiSelect
                        options={optionsQuality}
                        value={selectedQuality}
                        onChange={setSelectedQuality}
                        labelledBy="Select"
                    />
                    </div>
                </div>
                <div className="FlexRow MT10">
                    <div className="FlexCol Width30">
                        <div className="FlexRow AlignCenter">
                            <input type="checkbox" id="checkSiteDeepsee" onChange={handleChangeSiteDeepsee}/><label for="checkSiteDeepsee">No Fraud Deepsee ?</label>
                        </div>
                    </div>
                </div>
                <div className="FlexCol MT10 Width50">
                    <label>{activityTypeLabel}</label>
                    <input className="RangeInput" onChange={handleChangeActivityType} type="range" id="activityType" name="activityType" value={activityType} min="-1" max="3"/>          
                </div>
                <div className="FlexRow MT10">
                    <div className="FlexCol Width50">
                        <div className="FlexRow AlignCenter">
                            <input type="checkbox" id="checkUserRevenue" onChange={handleChangeUserRevenue}/><label for="checkUserRevenue">User Revenue</label>
                        </div>
                        <div className={"FlexCol "+(userRevenue? "" : "Hide")}>
                            <div className="FlexRow JustifyBetween Width80 MT10">
                                <span>From</span>
                                <input className="SmallNumberInput" type="number" onChange={handleChangeUserRevenueFrom}/>
                            </div>
                            <div className="FlexRow JustifyBetween Width80 MT5">
                                <span>To</span>
                                <input className="SmallNumberInput" type="number" onChange={handleChangeUserRevenueTo}/>
                            </div>
                        </div>
                    </div>
                    <div className="FlexCol ML20 Width50">
                        <div className="FlexRow AlignCenter">
                            <input type="checkbox" id="checkUserWebsite" onChange={handleChangeUserWebsite}/><label for="checkUserWebsite">User website amount</label>
                        </div>
                        <div className={"FlexCol "+(userWebsite? "" : "Hide")}>
                            <div className="FlexRow JustifyBetween Width80 MT10">
                                <span>From</span>
                                <input className="SmallNumberInput" type="number" onChange={handleChangeUserWebsiteFrom}/>
                            </div>
                            <div className="FlexRow JustifyBetween Width80 MT5">
                                <span>To</span>
                                <input className="SmallNumberInput" type="number" onChange={handleChangeUserWebsiteTo}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="FlexRow JustifyAround AlignCenter MT40 MR50">
            <div className="FlexRow AlignCenter">
                <label for="exportName">Export Name</label>
                <input type="text" id="exportName" name="exportName" onChange={handleChangeExportName} className="SmallNumberInput ML5 MR20" />

                <button className="ActionButton BRed DownloadButton" onClick={launchExport}>
                    <i className="fa-sharp fa-solid fa-gear MR5"></i>
                    Request export
                </button>
            </div>
        </div>
    </div>
    );
}
  
export default EasyExportComponent;
  
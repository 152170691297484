import './LineCardComponent.css';
import LineCardRowComponent from '../LineCardRowComponent/LineCardRowComponent';

function LineCardComponent(props) {

  return (
    <div className={"LineCard FlexCol " + props.childClass}>
        <h2 className={"Headline "+ props.headLineColor}>{props.title}</h2>
        <div className="ContainerLineRowTable">
          {props.data.map((row, index) => {
              if(props.action==="new_active"){
                  return <LineCardRowComponent
                      refresh={props.refresh}
                      child={row.site_child}
                      site_date={row.cohorte_first_perf}
                      childClass="LineCardRowElement"
                      action={props.action}
                      key={index}
                      site_vu={row.vu_similar}
                      siteId={row.site_id}
                      prio={row.score_prio}
                      id={row.site_id}
                      userId={row.user_id}
                      title={row.site_domain_pros}
                      phone={row.user_phone}
                      email={row.user_mail}
                      top_geo={row.top_geo}
                      name={row.user_firstname + " " + row.user_lastname}
                      plateform={row.user_plateform}
                      user_pros_name={row.user_pros_name}
                      wp_version={row.wp_version}
                      cmp={row.site_cmp}
                      ads_txt={row.site_ads_txt}
                  />
              }

              if(props.action==="inactive"){
                  return <LineCardRowComponent
                      refresh={props.refresh}
                      child={row.site_child}
                      childClass="LineCardRowElement"
                      key={index}
                      site_vu={row.vu_similar}
                      site_date={row.site_date_validation}
                      id={row.site_id}
                      siteId={row.site_id}
                      adm_name={row.adm_name}
                      prio={row.score_prio}
                      motif={row.motif}
                      action={props.action}
                      title={row.site_url}
                      phone={row.user_phone}
                      email={row.user_mail}
                      name={row.user_firstname + " " + row.user_lastname}
                      userId={row.user_id}
                      top_geo={row.top_geo}
                      plateform={row.user_plateform}
                      user_pros_name={row.user_pros_name}
                      wp_version={row.wp_version}
                      cmp={row.site_cmp}
                      ads_txt={row.site_ads_txt}
                  />
              }

              if(props.action==="important_detag"){
                  return <LineCardRowComponent
                      refresh={props.refresh}
                      child={row.site_child}
                      childClass="LineCardRowElement"
                      key={index}
                      site_vu={row.vu_similar}
                      site_date={row.cohorte_last_perf}
                      userId={row.user_id}
                      id={row.site_id}
                      motif={"Detag"}
                      siteId={row.site_id}
                      action={props.action}
                      prio={row.score_prio}
                      email={row.user_mail}
                      title={row.site_domain_pros}
                      phone={row.user_phone}
                      name={row.user_firstname + " " + row.user_lastname}
                      top_geo={row.top_geo}
                      plateform={row.user_plateform}
                      user_pros_name={row.user_pros_name}
                      wp_version={row.wp_version}
                      cmp={row.site_cmp}
                      ads_txt={row.site_ads_txt}
                  />
              }

              if(props.action==="opportunities"){
                  return <LineCardRowComponent
                      refresh={props.refresh}
                      child={row.site_child}
                      childClass="LineCardRowElement"
                      key={index}
                      site_vu={row.vu_similar}
                      userId={row.user_id}
                      prio={row.score_prio}
                      siteId={row.site_id}
                      id={row.site_id}
                      motif={row.motif}
                      action={props.action}
                      title={row.site_domain_pros}
                      phone={row.user_phone}
                      email={row.user_mail}
                      top_geo={row.top_geo}
                      name={row.user_firstname + " " + row.user_lastname}
                      plateform={row.user_plateform}
                      user_pros_name={row.user_pros_name}
                      wp_version={row.wp_version}
                      cmp={row.site_cmp}
                      ads_txt={row.site_ads_txt}
                  />
              }

              if(props.action==="alerts"){
                  return <LineCardRowComponent
                      refresh={props.refresh}
                      child={row.site_child}
                      childClass="LineCardRowElement"
                      key={index}
                      site_vu={row.vu_similar}
                      siteId={row.site_id}
                      userId={row.user_id}
                      id={row.atv_id}
                      prio={row.score_prio}
                      action={props.action}
                      title={row.site_domain_pros}
                      phone={row.user_phone}
                      email={row.user_mail}
                      name={row.user_firstname + " " + row.user_lastname}
                      plateform={row.user_plateform}
                      user_pros_name={row.user_pros_name}
                      cmp={row.site_cmp}
                      ads_txt={row.site_ads_txt}
                      type_alert={row.type_alert}
                      date_alert={row.date_alert}
                      version_alert={row.version_alert}
                      missing_line_alert={row.atv_rows}
                      nb_missing_line_alert={row.atv_missing_amount}
                  />
              }

              if(props.action==="reminders"){
                  return <LineCardRowComponent
                      refresh={props.refresh}
                      child={row.site_child}
                      childClass="LineCardRowElement"
                      key={index}
                      site_vu={row.vu_similar}
                      userId={row.user_id}
                      id={row.id}
                      adm_name={row.adm_name}
                      prio={row.prio}
                      motif={row.motif}
                      action={props.action}
                      phone={row.user_phone}
                      email={row.user_mail}
                      name={row.user_firstname + " " + row.user_lastname}
                      plateform={row.user_plateform}
                      user_pros_name={row.user_pros_name}
                      desc_reminder={row.description}
                      wp_version={row.wp_version}
                      cmp={row.site_cmp}
                      ads_txt={row.site_ads_txt}
                      date_reminder={row.date_reminder}
                  />
              }

              return '';
          })}
        </div>
    </div>
  );
}

export default LineCardComponent;

import './UsersWebsiteComponent.css';
import SitePublisherRowComponent from '../SitePublisherRowComponent/SitePublisherRowComponent'

function UsersWebsiteComponent(props) {
    return (
        <div className={"UsersWebsites " + props.childClass} id="UsersWebsites">
            <h3>User's websites</h3>
            <div className="UserWebsitesTable">
                <table>
                    <thead>
                        <tr>
                        <th>URL & SSP</th>
                        <th>ID</th>
                        <th>Info & Stats</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.listSiteInfos.map(row => {
                            return <SitePublisherRowComponent
                                key={row.site_id}
                                url={row.site_url}
                                id={row.site_id}
                                siteFraud={row.site_fraud}
                                child={row.site_child}
                                cmp={row.site_cmp}
                                adsTxt={row.site_ads_txt}
                                wordpress={row.wp_version}
                                site_moderation={row.site_moderation}
                                date_validation={row.site_date_validation}
                                site_actif={row.site_actif}
                                site_quality={row.site_ssp_pass}
                                raison_refus={row.raison_refus}
                                magic_tag={row.site_magic_tag_choosen}
                                />
                                })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
  
export default UsersWebsiteComponent;
import LateralNavComponent from "./LateralNavComponent/LateralNavComponent";
import './Home.css';
import { useState, useEffect } from "react";
import HeadLineComponent from "./HeadLineComponent/HeadLineComponent";
import { ToastContainer } from "react-toastify";
import AuthComponent from "./AuthComponent/AuthComponent";
import SplashLoadComponent from "./SplashLoadComponent/SplashLoadComponent";

function Home() {
  const pageTab = "overview";

  const [splashLoad, setSplashLoad] = useState(true);

  useEffect(() => {
    localStorage.setItem('crm_redirection', false);
    setSplashLoad(true);

    setTimeout(() => {
      setSplashLoad(false);
    }, 3000)
  }, []);

  return (
    <div className="Home">
      <AuthComponent/>
      {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <LateralNavComponent pageTab={pageTab}/>
      <HeadLineComponent childClass="Headline" title="Welcome to your CSM space !"/>
    </div>
  );
}

export default Home;

import './BillsRowComponent.css'

function BillsRowComponent(props) {
    const zeroPad = (num, places) => String(num).padStart(places, '0');
    const billIDBuilder = function(id, date){
        let trueID = (date.split('-'))[0] + "-" + zeroPad(id, 6);
        return trueID;
    }

    const billPaiementDue = function(date, net30){
        let d = new Date((date.split(' '))[0]);
        if(net30===0){
          d.setMonth(d.getMonth()+2);
        } else {
          d.setMonth(d.getMonth()+1);
        }
        return d.toISOString().split('T')[0];
      }
      
    return (
        <tr className={"BillsRow " + props.childClass}>
            <td className="P5">
                <div className='FlexRow AlignCenter'>
                {billIDBuilder(props.bill_id, props.bill_date)}
                {props.bill_amount===null ? <p className='ML20 SmallFont'>Exceptional bill</p> : ''}
                </div>
            </td>
            <td className="P5">{(props.bill_date.split(" "))[0]}</td>
            <td className="P5">{billPaiementDue(props.bill_date, props.user_net30)}</td>
            <td className="P5">{(props.bill_amount!==null ? (props.bill_currency==="$"? props.bill_amount_dollar+"$" : props.bill_amount+"€") :(props.bill_currency==="$"? props.bill_exceptional_dollar+"$" : props.bill_exceptional+"€"))}</td>
            <td className="P5">Virement</td>
            <td className="P5">{props.payment_state}</td>
            <td className="P5">{props.payment_error}</td>
        </tr>
  );
}
  
export default BillsRowComponent;